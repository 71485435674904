* {
    padding: 0;
    margin: 0;
}

html, body {
    height: 100%;
}

#root {
    height: 100%;
}
